<template>
  <div class="features-section relative overflow-hidden">
    <div class="features-section-grid mx-auto">
      <div :class="['features-item flex-column flex-sm-row inspiration-item align-sm-center align-md-start', currentTab === 0 ? 'd-flex': 'd-none']">
        <div class="features-item-info relative flex-shrink-0 pt-sm-8 order-1 order-sm-0">
            <span v-if="$vuetify.breakpoint.smAndUp" class="text-title-3 text-uppercase font-weight-regular accent--text">
              Our features
            </span>

          <h2 class="mt-sm-4 mb-6 mb-sm-9 black-100--text">
            From <br class="d-sm-none"> <strong class="text-uppercase font-weight-black accent--text">Inspiration</strong> <br>
            to Project Completion
          </h2>

          <p class="mb-9 mb-sm-0 pb-sm-10 black-100--text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>

          <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center mt-sm-8 mt-md-16">
            <img :src="require('@/assets/images/landings/arrow-right.svg')" alt="" class="arrow-right">
            <router-link :to="{name: 'Login'}" class="px-5 text-title-3 font-weight-black accent--text text-decoration-none">
              Learn More
            </router-link>
          </div>

          <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex justify-center">
            <UiBtn color="accent" width="236" height="42" :to="{name: 'Login'}"
                   class="text-body font-weight-semi-bold">Get Started</UiBtn>
          </div>
        </div>

        <div class="features-item-image">
          <img :src="require('@/assets/images/landings/featuresLanding/ourFeaturesSection/inspiration.png')" alt="" class="relative fill-width">
        </div>
      </div>

      <div :class="['features-item flex-column flex-sm-row sketch-item align-sm-center align-md-start', currentTab === 1 ? 'd-flex': 'd-none']">
        <div class="features-item-info relative flex-shrink-0 pt-sm-8 order-1 order-sm-0">
            <span v-if="$vuetify.breakpoint.smAndUp" class="text-title-3 text-uppercase font-weight-regular accent--text">
              Our features
            </span>

          <h2 class="mt-sm-4 mb-6 mb-sm-9 black-100--text">
            From <br class="d-sm-none"> <strong class="text-uppercase font-weight-black accent--text">Sketches</strong> <br>
            to Project Completion
          </h2>

          <p class="mb-9 mb-sm-0 pb-sm-10 black-100--text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>

          <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center mt-sm-8 mt-md-16">
            <img :src="require('@/assets/images/landings/arrow-right.svg')" alt="" class="arrow-right">
            <router-link :to="{name: 'Login'}" class="px-5 text-title-3 font-weight-black accent--text text-decoration-none">
              Learn More
            </router-link>
          </div>

          <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex justify-center">
            <UiBtn color="accent" width="236" height="42" :to="{name: 'Login'}"
                   class="text-body font-weight-semi-bold">Get Started</UiBtn>
          </div>
        </div>

        <div class="features-item-image">
          <img :src="require('@/assets/images/landings/featuresLanding/ourFeaturesSection/sketches.png')" alt="" class="relative fill-width">
        </div>
      </div>

      <div :class="['features-item flex-column flex-sm-row product-item align-sm-center align-md-start', currentTab === 2 ? 'd-flex': 'd-none']">
        <div class="features-item-info relative flex-shrink-0 pt-sm-8 order-1 order-sm-0">
          <span v-if="$vuetify.breakpoint.smAndUp" class="text-title-3 text-uppercase font-weight-regular accent--text">
            Our features
          </span>

          <h2 class="mt-sm-4 mb-6 mb-sm-9 black-100--text">
            From <br class="d-sm-none"> <strong class="text-uppercase font-weight-black accent--text">Product</strong> <br>
            to Project Completion
          </h2>

          <p class="mb-9 mb-sm-0 pb-sm-10 black-100--text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
          </p>

          <div v-if="$vuetify.breakpoint.smAndUp" class="d-flex align-center mt-sm-8 mt-md-16">
            <img :src="require('@/assets/images/landings/arrow-right.svg')" alt="" class="arrow-right">
            <router-link :to="{name: 'Login'}" class="px-5 text-title-3 font-weight-black accent--text text-decoration-none">
              Learn More
            </router-link>
          </div>

          <div v-if="$vuetify.breakpoint.xsOnly" class="d-flex justify-center">
            <UiBtn color="accent" width="236" height="42" :to="{name: 'Login'}"
                   class="text-body font-weight-semi-bold">Get Started</UiBtn>
          </div>
        </div>

        <div class="features-item-image">
          <img :src="require('@/assets/images/landings/featuresLanding/ourFeaturesSection/product.png')" alt="" class="relative fill-width">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "OurFeaturesSection",
  components: {
    UiBtn,
  },
  data() {
    return {
      currentTab: 0,
      animationInterval: null
    }
  },
  mounted() {
    this.animationInterval = setInterval(() => {
      if (this.currentTab === 2) {
        this.currentTab = 0;
        return;
      }
      this.currentTab += 1;
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.animationInterval);
  }
}
</script>

<style scoped lang="scss">
.features-section {
  padding: 40px 40px 60px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 20px 40px;
  }

  .features-section-grid {
    max-width: 1380px;
  }

  .features-item {
    height: 574px;

    @media screen and (max-width: 1200px) {
      height: 470px;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      height: auto;
    }
  }

  .features-item-info {
    z-index: 2;

    h2 {
      font-size: 42px;
      line-height: 48px;
      font-weight: 800;

      strong {
        font-size: 48px;
        line-height: 48px;
      }

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 32px;
        line-height: 38px;

        strong {
          font-size: 38px;
          line-height: 38px;
        }
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        font-weight: 400;
      }
    }

    p {
      max-width: 316px;
      font-size: 20px;
      line-height: 27px;

      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  .inspiration-item .features-item-image {
    max-width: 730px;
  }

  .sketch-item .features-item-image {
    max-width: 760px;
  }

  .product-item .features-item-image {
    max-width: 796px;
  }

  .features-item-image {
    &::before {
      content: "";
      position: absolute;
      top: -40px;
      bottom: -60px;
      left: 50%;
      width: 100vw;
      background: transparent url("~@/assets/images/landings/background-pattern.png") repeat 50% center;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        display: none;
      }
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin: 0 -36px;
    }
  }
}
</style>
