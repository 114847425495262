<template>
  <div class="project-tools-section">
    <div v-if="$vuetify.breakpoint.smAndUp" class="mb-3 text-title-3 text-uppercase font-weight-regular accent--text text-center">
      End-to-End
    </div>
    <SectionTitle title="Any Project <br> Every tool" class="mb-10 mb-sm-16 pb-sm-2 text-center"/>

    <div v-if="$vuetify.breakpoint.smAndUp" class="tools-wrap mx-auto d-flex">
      <div class="tool-tab-content overflow-hidden d-flex align-center justify-center">
        <template v-for="(tab, idx) in tabsItems">
          <img :key="idx" v-show="currentTab === idx"
            :src="require(`@/assets/images/landings/featuresLanding/projectToolsSection/${tab.image}`)" alt="" class="fill-width">
        </template>
      </div>

      <div class="tool-tab-items ml-4 flex-shrink-0">
        <div
          v-for="(tab, idx) in tabsItems"
          :key="idx"
          @click="currentTab = idx"
          :class="['tool-tab-item mb-1 px-8 cursor-pointer black-100--text', {'is-active': currentTab === idx}]">
          <h6 class="pb-4 text-body-md font-weight-bold">{{ tab.title }}</h6>
          <p class="text-body pb-6 mb-0" v-show="currentTab === idx">{{ tab.text }}</p>
        </div>
      </div>
    </div>


    <template v-if="$vuetify.breakpoint.xsOnly">
      <div v-for="(tab, idx) in tabsItems" :key="idx" class="tool-card mb-11 pt-6 px-6 rounded overflow-hidden gray-10 black-100--text">
        <h6 class="pb-3 text-body-md font-weight-bold accent--text">{{ tab.title }}</h6>
        <p class="text-body mb-2">{{ tab.text }}</p>
        <div class="mx-n10 mb-n6">
          <img :src="require(`@/assets/images/landings/featuresLanding/projectToolsSection/${tab.image}`)" alt="" class="fill-width">
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";

export default {
  name: "ProjectToolsSection",
  components: {
    SectionTitle,
  },
  data() {
    return {
      currentTab: 0,
      tabsItems: [
        {
          title: "Discover",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget",
          image: "discover.png",
        },
        {
          title: "Communication",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget",
          image: "communication.png",
        },
        {
          title: "Visibility",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget",
          image: "visibility.png",
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.project-tools-section {
  padding: 212px 40px 184px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 44px 20px 8px;
    background-color: var(--v-gray-100-base);

    .section-title {
      color: var(--v-gray-0-base);
    }
  }

  .tools-wrap {
    max-width: 1044px;
    padding: 62px 48px;
    border-radius: 2px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

    @media #{map-get($display-breakpoints, 'sm-only')} {
      padding: 48px 32px;
    }
  }

  .tool-tab-content {
    box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
  }

  .tool-tab-items {
    width: 278px;
  }

  .tool-tab-item {
    padding-top: 16px;
    border-right: 5px solid var(--v-gray-80-base);

    h6 {
      color: var(--v-gray-80-base);
    }

    p {
      line-height: 19px;
    }

    &.is-active {
      padding-top: 24px;
      border-right-color: var(--v-accent-base);
      background-color: var(--v-gray-10-base);

      h6 {
        color: var(--v-accent-base);
      }
    }
  }
}
</style>
