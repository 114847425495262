<template>
  <component :is="tag" v-html="title" class="section-title"/>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: "h2"
    }
  },
}
</script>

<style scoped lang="scss">
.section-title {
  font-size: 42px;
  line-height: 48px;
  font-weight: 800;
  color: var(--v-black-100-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 32px;
    line-height: 35px;
    font-weight: 600;
  }
}
</style>
