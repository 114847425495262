<template>
  <div>
    <OurFeaturesSection id="features"/>
    <WorkflowSection id="workflow"/>
    <TasksSection id="tasks"/>
    <VersionControlSection id="versions"/>
    <ProjectToolsSection id="tools"/>
    <GetStartedToday id="get-started"/>

    <ul v-if="$vuetify.breakpoint.smAndUp" class="page-stepper d-flex flex-column align-center justify-space-between pa-0">
      <li
        v-for="(section, idx) in allSections"
        :key="idx"
        @click="selectSlide(idx)"
        :class="['d-flex align-center cursor-pointer', {'is-active': currentSection === idx}]"
      >
        <IconCircleFilled/>
      </li>
    </ul>

  </div>
</template>

<script>
import OurFeaturesSection from "@/views/landings/FeaturesLanding/OurFeaturesSection";
import WorkflowSection from "@/views/landings/FeaturesLanding/WorkflowSection";
import TasksSection from "@/views/landings/FeaturesLanding/TasksSection";
import VersionControlSection from "@/views/landings/FeaturesLanding/VersionControlSection";
import ProjectToolsSection from "@/views/landings/FeaturesLanding/ProjectToolsSection";
import GetStartedToday from "@/components/landings/GetStartedToday";

export default {
  name: "FeaturesLanding",
  components: {
    IconCircleFilled: () => import('@/components/icons/IconCircleFilled'),

    OurFeaturesSection,
    WorkflowSection,
    TasksSection,
    VersionControlSection,
    ProjectToolsSection,
    GetStartedToday
  },
  data() {
    return {
      currentSection: 0,
      allSections: [
        "features",
        "workflow",
        "tasks",
        "versions",
        "tools",
        "get-started",
      ],
      observer: null,
    }
  },
  computed: {
    sectionComponent() {
      return document.getElementById(this.allSections[this.currentSection]);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initIntersectionObserver();
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    initIntersectionObserver() {
      this.observer = new IntersectionObserver(this.onElementObserved, {
        rootMargin: "0px",
        threshold: 0.35,
      });

      this.allSections.forEach(id => {
        const targetSection = document.getElementById(id);
        this.observer.observe(targetSection);
      });
    },
    onElementObserved(entries) {
      if (entries && entries[0].isIntersecting) {
        this.currentSection = this.allSections.indexOf(entries[0].target.id);
      }
    },
    selectSlide(slide) {
      this.currentSection = slide;

      document.getElementById(this.allSections[slide]).scrollIntoView({
        block: "center",
        behavior: "smooth"
      });
    },
  }
}
</script>

<style scoped lang="scss">
.page-stepper {
  position: fixed;
  z-index: 2;
  right: 40px;
  top: calc(50% - 94px);
  transform: translateY(-50%);

  &::before {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    left: 50%;
    width: 1px;
    transform: translateX(-50%);
    background-color: #776D6D;
  }

  li {
    height: 16px;
    margin-bottom: 44px;
    list-style-type: none;

    &:last-child {
      margin-bottom: 0;
    }

    &:first-child,
    &:last-child {
      svg {
        width: 16px;
      }
    }

    svg {
      width: 10px;
      transition: none;
      color: var(--v-gray-45-base);
      z-index: 2;
    }

    &.is-active {
      svg {
        width: 16px;
        color: var(--v-accent-base);
      }
    }
  }
}
</style>
