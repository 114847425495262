<template>
  <div class="workflow-section">
    <div class="workflow-section-grid d-flex flex-column flex-sm-row mx-auto">
      <div class="workflow-section-info flex-shrink-0">
        <SectionTitle title="Customize your <br> workflow" class="mb-4 mb-sm-7"/>
        <p class="mb-10 mb-sm-0 black-100--text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Et ut rutrum viverra amet justo. Egestas morbi vel imperdiet sed semper. Senectus ac eget
        </p>
      </div>
      <div class="workflow-section-image">
        <img :src="require('@/assets/images/landings/featuresLanding/customize-workflow.png')" alt="" class="fill-width">
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/landings/SectionTitle";

export default {
  name: "WorkflowSection",
  components: {
    SectionTitle,
  },
}
</script>

<style scoped lang="scss">
.workflow-section {
  padding: 250px 40px 214px;

  @media screen and (max-width: 768px) {
    padding: 150px 40px 114px;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 36px 20px 46px;
    background-color: var(--v-gray-30-base);
  }

  .workflow-section-grid {
    max-width: 1380px;
  }

  .workflow-section-info {
    margin-right: 200px;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      margin-right: 48px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      margin-right: 0;
    }

    .section-title {
      @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    p {
      max-width: 320px;
      font-size: 20px;
      line-height: 27px;

      @media screen and (max-width: 768px) {
        max-width: 242px;
        font-size: 15px;
        line-height: 20px;
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        max-width: 266px;
      }
    }
  }

  .workflow-section-image {
    max-width: 488px;

    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 100%;
    }
  }
}
</style>
