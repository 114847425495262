<template>
  <div class="get-started fill-width gray-0--text">
    <div class="get-started-grid d-flex align-center mx-auto fill-width">
      <div class="get-started-img-wrap mr-sm-11 overflow-hidden">
        <img :src="require('@/assets/images/landings/get-started.png')" alt="" class="fill-width fill-height object-cover"/>
      </div>

      <div class="get-started-form px-11 px-sm-0">
        <h2 class="mb-2">Get started today</h2>
        <p class="get-started-form-descr mb-6 font-weight-regular">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

        <ValidationObserver tag="div" v-slot="{ handleSubmit }">
          <v-form @submit.prevent="handleSubmit(goLogin)">

            <ValidationProvider class="mb-10 mb-sm-4" tag="div" mode="eager" rules="email|required" name="Email" vid="email" v-slot="{ errors }">
              <v-text-field
                v-model="emailInput"
                dense
                solo
                flat
                hide-details
                color="red"
                class="rounded-0"/>
              <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
            </ValidationProvider>
            <UiBtn type="submit" color="accent" width="212" height="40">
              <span v-if="$vuetify.breakpoint.smAndUp" class="text-title-3 font-weight-regular">Get Started</span>
              <span v-else class="text-captions-1 font-weight-semi-bold">Stay Informed</span>
            </UiBtn>
          </v-form>
        </ValidationObserver>

        <p v-if="$vuetify.breakpoint.smAndUp" class="mt-8 mb-0 text-body-md">Talk to one our representatives first</p>
      </div>
    </div>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn";

export default {
  name: "GetStartedToday",
  components: {
    UiBtn
  },
  data() {
    return {
      emailInput: "",
    }
  },
  methods: {
    goLogin() {
      this.$router.push({name: "Login", query: {email: this.emailInput}});
    }
  }
}
</script>

<style scoped lang="scss">
.get-started {
  padding: 64px 40px;
  background-color: var(--v-gray-120-base);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 0 0 76px;
    background-color: var(--v-gray-60-base);
  }

  .get-started-grid {
    max-width: 1402px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      text-align: center;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      text-align: left;
    }
  }

  .get-started-img-wrap {
    max-width: 388px;
    max-height: 256px;

    @media #{map-get($display-breakpoints, 'sm-only')} {
      max-width: 318px;
    }

    @media screen and (max-width: 767px) {
      max-width: 420px;
      margin-bottom: 58px;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 100%;
      max-height: 282px;
    }
  }

  .get-started-form {
    h2 {
      font-size: 48px;
    }

    .get-started-form-descr {
      font-size: 20px;
      line-height: 27px;
    }

    ::v-deep .v-input input {
      color: var(--v-gray-100-base);
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      h2 {
        font-size: 38px;
      }

      .get-started-form-descr {
        font-size: 16px;
        line-height: 23px;
      }
    }

    @media screen and (max-width: 767px) {
      form {
        margin-top: 32px;
        text-align: center;
      }

      ::v-deep .v-input input {
        text-align: center;
      }
    }
  }
}
</style>
